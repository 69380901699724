<template>
  <modal :name="`modal-product-${categoryId}-${productId}`" class="modal-product" ref="modal" v-if="product">
    <div slot="content" class="pb-9">
      <div class="block text-center">
        <strong class="text-h5 leading-h2 font-bold uppercase tracking-xl">{{ $t('Quick Add') }}</strong>
        <h2 class="font-black leading-h2 text-caption font-serif mb-lg">{{ product.name }}</h2>
      </div>
      <div class="flex flex-wrap pb-7">
        <div class="w-full lg:w-1/2 text-center product-image-container" :style="`height: ${getProductImageHeight(product)}px;`">
          <img
            class="product-image"
            :src="thumbnailObj.src"
            :alt="product.name"
          >
        </div>
        <p v-html="product.short_description" class="mb-5 md:hidden" />
        <div class="w-full lg:w-1/2 md:py-60px sm:px-30px lg:px-0 lg:py-0 sticky md:relative bottom-0 md:bottom-auto pt-4 sticky-bg">
          <div
            v-if="product.type_id !== 'grouped' && product.type_id !== 'bundle'"
            class="h-full"
          >
            <p v-html="product.short_description" class="mb-5 hidden md:block" />
            <div>
              <p class="text-h6 uppercase text-dark tracking-thick leading-thick mb-3">
                {{ $t('Price') }}
              </p>
              <div class="font-normal text-dark text-lg leading-h4 mb-md">
                <div v-if="!rulePrice">
                  <div v-if="product.special_price && product.priceInclTax && product.originalPriceInclTax">
                    <span>{{ product.priceInclTax * product.qty | price }}</span>&nbsp;
                    <span>{{ product.originalPriceInclTax * product.qty | price }}</span>
                  </div>
                  <div v-if="!product.special_price && product.priceInclTax">
                    {{ product.qty > 0 ? product.priceInclTax * product.qty : product.priceInclTax | price }}
                  </div>
                </div>
                <div v-else>
                  {{ product.qty ? rulePrice * product.qty : rulePrice | price }}
                </div>
                <div v-if="rewardSpendEligible">
                  <div class="italic text-md">or {{ product.qty ? parseFloat(getPointPrice * product.qty).toFixed(2) : getPointPrice }} Points</div>
                </div>
              </div>
            </div>
            <div class="w-full mb-xs" v-if="product.type_id !== 'grouped' && product.type_id !== 'bundle'">
              <base-input-number
                :min="incrementStep"
                :name="$t('Quantity')"
                class="quantity-select"
                :increment="incrementStep"
                @blur="$v.$touch()"
                :validations="[
                    {
                      condition: $v.product.qty.$error && !$v.product.qty.minValue,
                      text: $t('Quantity must be above 0')
                    },
                    {
                      condition: $v.product.qty.isValueMultiple && incrementStep !== 1,
                      text: $t('Quantity must be multiple of ') + incrementStep
                    }
                  ]"
                v-model="product.qty"
              >
                {{ $t('Qty') }}
              </base-input-number>
              <p class="text-average leading-loose pb-2 pt-1 text-grey-23 tracking-sm">
                {{ $t('Available for purchase in increments of ') + incrementStep }}
              </p>
            </div>
            <earn-points
              v-if="product.type_id !== 'grouped' && product.type_id !== 'bundle'"
              :product="product"
              :qty="product.qty"
            />
            <button-with-icon
              v-if="rewardSpendEligible"
              @click.native="addToRewardsCartWrapper"
              :disabled="!isInStock || loading || OfflineOnly"
              :loading="loading"
              :color="`primary2`"
              class="popup-button flex-grow pr-5 pl-10 w-full mt-3">
                  <span v-if="OnlineOnly">
                    {{ $t('Buy with') }} {{ totalPointsPrice }} {{ $t('points') }}
                  </span>
              <span v-else>
                    {{ $t('You are offline.') }}
                  </span>
            </button-with-icon>
            <div v-if="rewardSpendEligible" class="text-md px-2 pl-10">Available Balance: {{ currentBalance }} Points</div>
            <add-to-cart
              :disabled="$v.product.qty.$error &&  !$v.product.qty.minValue"
              :product="product"
              :category-id="categoryId"
              :product-id="productId"
              class="w-full mt-3"
            >{{ $t('Buy with') }} {{ getTotalPrice | price }}</add-to-cart>
          </div>
          <div
            v-if="(product.type_id === 'grouped' || product.type_id === 'bundle') && productLinks.length"
            class="h-full"
          >
            <product-links :product="product" :products="productLinks" />
          </div>
        </div>
      </div>
      <div class="block text-left">
        <p
          v-if="product.type_id !== 'grouped' && product.type_id !== 'bundle'"
          class="text-h5 leading-tight tracking-xs uppercase text-grey-24"
        >
          {{ $t('SKU: ') + product.sku }}
        </p>
        <p
          v-else
          class="text-h5 leading-tight tracking-xs uppercase text-grey-24"
        >
          <span v-if="selectedProduct.sku">
            {{ $t('SKU: ') + selectedProduct.sku }}
          </span>
        </p>
      </div>
    </div>
  </modal>
</template>

<script>
import { minValue } from 'vuelidate/lib/validators'
import Modal from 'theme/components/core/Modal'
import { ProductTile } from '@vue-storefront/core/modules/catalog/components/ProductTile.ts'
import ProductImageScaling from 'theme/mixins/blocks/ProductImageScaling'
import EarnPoints from 'theme/components/theme/EarnPoints'
import ProductLinks from 'theme/components/core/ProductLinks'
import PriceRules from 'theme/mixins/blocks/PriceRules'
import AddToCart from 'theme/components/core/blocks/Product/AddToCart'
import BaseInputNumber from 'theme/components/core/blocks/Product/BaseInputNumber'
import { getProductQtyIncrement } from 'theme/helpers/getProductQtyIncrement'
import ButtonWithIcon from 'theme/components/theme/ButtonWithIcon'
import VueOfflineMixin from 'vue-offline/mixin'
import i18n from '@vue-storefront/i18n'
import EarnedPoints from 'theme/mixins/blocks/EarnedPoints'
import { productThumbnailPath } from '@vue-storefront/core/helpers'
import config from 'config'
import debounce from 'lodash-es/debounce'

const isValueMultiple = (step) => (value) => value % step !== 0

export default {
  name: 'ProductPopup',
  components: {
    AddToCart,
    EarnPoints,
    ProductLinks,
    Modal,
    BaseInputNumber,
    ButtonWithIcon
  },
  mixins: [
    ProductTile,
    ProductImageScaling,
    PriceRules,
    VueOfflineMixin,
    EarnedPoints
  ],
  props: {
    product: {
      type: null,
      required: true
    },
    categoryId: {
      type: [Number, String],
      required: true
    },
    productId: {
      type: [Number, String],
      required: true
    }
  },
  data () {
    return {
      incrementStep: 1,
      selectedProduct: {},
      loading: false,
      currentBalance: 0
    }
  },
  beforeMount () {
    this.incrementStep = getProductQtyIncrement(this.product || {})
    this.$bus.$on('after-product-added', this.closePopup)
    this.$bus.$on('selected-new-product', this.selectedNewProduct)
  },
  async mounted () {
    this.$nextTick(async () => {
      if (!this.$isServer && this.product) {
        // console.log('Setting Current Balance Brand ID:', this.product.brand_id)
        this.currentBalance = await this.getCurrentBalance(this.product.brand_id)

        this.$watch(
          '$refs.modal.isVisible',
          (val) => {
            this.updateAvailableBalance()
          }
        )

        this.$bus.$on('cart-after-itemchanged', this.updateAvailableBalance)
      }
    })
  },
  // watch: {
  //   isVisible: async function (val) {
  //     if (val && this.currentBalance === 0 && !this.$isServer) {
  //       // console.log('Watcher Setting Current Balance Brand ID:', this.product.brand_id)
  //       this.currentBalance = await this.getCurrentBalance(this.product.brand_id)
  //     }
  //   }
  // },
  computed: {
    getTotalPrice () {
      let rulePrice = this.getRulePrice(this.product)

      if (rulePrice) {
        return rulePrice * this.product.qty
      }
      return this.product.priceInclTax * this.product.qty
    },
    getPointPrice () {
      let isFloat = (n) => Number(n) === n && n % 1 !== 0
      let rulePrice = this.getRulePrice(this.product)
      let price = rulePrice || this.product.point_redemption_price
      return isFloat(price) ? price.toFixed(2) : price
    },
    totalPointsPrice () {
      let rulePrice = this.getRulePrice(this.product)
      let price = (this.product.point_redemption_price * (this.product.qty ? this.product.qty : this.incrementStep)).toFixed(2)

      if (rulePrice) price = (rulePrice * (this.product.qty ? this.product.qty : this.incrementStep)).toFixed(2)
      return price
    },
    rewardSpendEligible () {
      return this.product.point_can_spend
    },
    points: function () {
      let points = parseFloat(this.product.loyaltyPoints * this.product.qty).toFixed(2)

      if (points > 0) {
        return points
      }
      return 0
    },
    productLinks () {
      let productLinks = this.product.type_id === 'grouped' ? this.product['product_links'] : this.product['bundle_options'][0]['product_links']
      return productLinks || []
    },
    rulePrice () {
      return this.getRulePrice(this.product)
    },
    isInStock () {
      return this.product.stock ? this.product.stock.is_in_stock : false
    },
    thumbnail () {
      // todo: play with the image based on category page filters - eg. when 'red' color is chosen, the image is going to be 'red'
      let thumbnail = productThumbnailPath(this.product)
      return this.getThumbnail(thumbnail, config.products.gallery.width, config.products.gallery.height)
    },
    thumbnailObj () {
      return {
        src: this.thumbnail,
        loading: this.placeholder,
        error: this.placeholder
      }
    }
  },
  methods: {
    updateAvailableBalance: debounce(async function () {
      if (this.$refs.modal && this.$refs.modal.isVisible && this.product) {
        this.currentBalance = await this.getCurrentBalance(this.product.brand_id)
      }
    }, 500),
    notifyUser (notificationData) {
      this.$store.dispatch('notification/spawnNotification', notificationData)
    },
    closePopup () {
      this.$bus.$emit('modal-hide', 'modal-product-' + this.categoryId + '-' + this.productId)
    },
    selectedNewProduct (product) {
      this.selectedProduct = product
    },
    addToRewardsCartWrapper: debounce(function () {
      this.addToRewardsCart()
    }, 500, {
      leading: true,
      trailing: false
    }),
    async addToRewardsCart () {
      this.loading = true

      try {
        const diffLog = await this.$store.dispatch('cart/upsertLoyaltyItem', {
          product: this.product,
          qty: this.product.qty,
          upsert: false // we want to update on old qty
        })

        if (diffLog) {
          let hasError = false

          if (diffLog.clientNotifications && diffLog.clientNotifications.length > 0) {
            diffLog.clientNotifications.forEach(notificationData => {
              if (notificationData.type === 'error') {
                hasError = true
              }
              this.notifyUser({
                type: notificationData.type,
                message: notificationData.message,
                action1: {label: i18n.t('OK')},
                action2: {
                  label: i18n.t('Proceed to checkout'),
                  action: () => {
                    this.$router.push(this.localizedRoute('/cart'))
                  }
                }
              })
            })
          } else if (diffLog.serverResponses && diffLog.serverResponses.length > 0) {
            diffLog.serverResponses.forEach(notificationData => {
              if (notificationData.result.resultCode === 400) {
                hasError = true
                this.notifyUser({
                  type: 'error',
                  message: notificationData.result.result,
                  action1: {label: i18n.t('OK')},
                  action2: null
                })
              }
            })
          }

          if (!hasError) {
            // this.$store.dispatch('loyalty/addRewardItem', { productToAdd: this.product })
            this.$bus.$emit('modal-hide', 'modal-points-quick-view')
          }
        } else {
          this.notifyUser({
            type: 'success',
            message: i18n.$t('Product has been added to the cart!'),
            action1: {label: i18n.t('OK')},
            action2: null
          })
          // this.$store.dispatch('loyalty/addRewardItem', { productToAdd: this.product })
          this.$bus.$emit('modal-hide', 'modal-points-quick-view')
        }
        this.loading = false
        this.$bus.$emit('after-product-added', {})
        return diffLog
      } catch (err) {
        this.notifyUser({
          type: 'error',
          message: err || 'An error occurred....',
          action1: {label: i18n.t('OK')}
        })
        this.loading = false
        return null
      }
    }
  },
  beforeDestroy () {
    this.$bus.$off('after-product-added', this.closePopup)
    this.$bus.$off('selected-new-product', this.selectedNewProduct)
    this.$bus.$off('cart-after-itemchanged', this.updateAvailableBalance)
  },
  validations () {
    return {
      product: {
        qty: {
          minValue: minValue(1),
          isValueMultiple: isValueMultiple(this.incrementStep)
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.product-image-container {
  max-height: 400px
}
.product-image {
  object-fit: contain;
  max-width: 310px;
  height: 100%;
  width: 100%;
}

.sticky-bg {
  background: rgba(255, 255, 255, 0.7);

  @media screen and (min-width: theme('screens.md')) {
    background: none;
  }
}
</style>
