var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.productLink)?_c('div',{staticClass:"my-sm"},[_c('base-input-number',{staticClass:"product-qty",attrs:{"min":_vm.incrementStep,"name":"qty","increment":_vm.incrementStep,"validations":[
        {
          condition: _vm.$v.productLink.qty.$error && !_vm.$v.productLink.qty.minValue,
          text: _vm.$t('Quantity must be above 0')
        },
        {
          condition: _vm.$v.productLink.qty.isValueMultiple && _vm.incrementStep !== 1,
          text: _vm.$t('Quantity must be multiple of ') + _vm.incrementStep
        }
      ],"value":_vm.product.product.qty},on:{"blur":function($event){return _vm.$v.$touch()},"input":_vm.updateQty}},[_vm._v("\n    "+_vm._s(_vm.$t('Qty'))+"\n  ")]),_vm._v(" "),_c('earn-points',{staticClass:"mt-sm",attrs:{"product":_vm.productLink,"qty":_vm.productLink.qty}}),_vm._v(" "),_c('add-to-cart',{staticClass:"w-full mt-sm",attrs:{"disabled":_vm.$v.productLink.qty.isValueMultiple || _vm.$v.productLink.qty.$error && !_vm.$v.productLink.qty.minValue,"product":_vm.productLink,"is-product-page":_vm.isProductPage}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }