<template>
  <div
    class="my-sm"
    v-if="productLink"
  >
    <base-input-number
      :min="incrementStep"
      name="qty"
      class="product-qty"
      :increment="incrementStep"
      @blur="$v.$touch()"
      :validations="[
          {
            condition: $v.productLink.qty.$error && !$v.productLink.qty.minValue,
            text: $t('Quantity must be above 0')
          },
          {
            condition: $v.productLink.qty.isValueMultiple && incrementStep !== 1,
            text: $t('Quantity must be multiple of ') + incrementStep
          }
        ]"
      :value="product.product.qty"
      @input="updateQty"
    >
      {{ $t('Qty') }}
    </base-input-number>
    <earn-points
      class="mt-sm"
      :product="productLink"
      :qty="productLink.qty"
    />
    <add-to-cart
      :disabled="$v.productLink.qty.isValueMultiple || $v.productLink.qty.$error && !$v.productLink.qty.minValue"
      :product="productLink"
      :is-product-page="isProductPage"
      class="w-full mt-sm"
    />
  </div>
</template>

<script>
import AddToCart from 'theme/components/core/blocks/Product/AddToCart'
import BaseInputNumber from 'theme/components/core/blocks/Product/BaseInputNumber'
import EarnPoints from 'theme/components/theme/EarnPoints'
import { minValue } from 'vuelidate/lib/validators'
import { getProductQtyIncrement } from 'theme/helpers/getProductQtyIncrement'

const isValueMultiple = (step) => (value) => value % step !== 0

export default {
  name: 'ProductLink',
  components: {
    AddToCart,
    BaseInputNumber,
    EarnPoints
  },
  props: {
    product: {
      type: Object,
      required: true,
      default: () => {}
    },
    isProductPage: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      incrementStep: 1,
      changeCount: 0
    }
  },
  beforeMount () {
    this.incrementStep = getProductQtyIncrement(this.productLink)
  },
  methods: {
    updateQty (event) {
      if (this.product.product.qty != event) {
        this.changeCount++
        this.product.product.qty = event
        this.$forceUpdate()
      }
    }
  },
  computed: {
    productLink () {
      return this.product.product
    }
  },
  validations () {
    return {
      productLink: {
        qty: {
          minValue: minValue(1),
          isValueMultiple: isValueMultiple(this.incrementStep)
        }
      }
    }
  }
}
</script>
