<template>
  <button-with-icon
    type="button"
    @click.native="addToCartWrapper(product)"
    :disabled="isProductDisabled"
    data-testid="addToCart"
    :color="color"
    :loading="isLoading"
  >
    <span v-if="added">
      {{ $t('Added') }}
    </span>
    <span v-if="failed">
      {{ $t('Failed') }}
    </span>
    <slot v-if="!added && !failed && isInStock && OnlineOnly">
      {{ $t('Add to order') }}
    </slot>
    <span v-else-if="!added && !failed && OnlineOnly">
      {{ $t('Out of Stock') }}
    </span>
    <span v-else-if="!added && !failed && OfflineOnly">
      {{ $t('You are offline.') }}
    </span>
  </button-with-icon>
</template>

<script>
import { mapGetters } from 'vuex'
import { formatProductMessages } from '@vue-storefront/core/filters/product-messages'
import focusClean from 'theme/components/theme/directives/focusClean'
import ButtonWithIcon from 'theme/components/theme/ButtonWithIcon'
import { AddToCartExtend } from 'theme/mixins/blocks/AddToCart'
import PriceRules from 'theme/mixins/blocks/PriceRules'
import VueOfflineMixin from 'vue-offline/mixin'
import debounce from 'lodash-es/debounce'

export default {
  mixins: [
    AddToCartExtend,
    PriceRules,
    VueOfflineMixin
  ],
  props: {
    color: {
      type: String,
      required: false,
      default: 'primary'
    },
    isProductPage: {
      type: Boolean,
      default: false
    },
    categoryId: {
      type: [Number, String],
      required: false
    },
    productId: {
      type: [Number, String],
      required: false
    }
  },
  directives: { focusClean },
  components: { ButtonWithIcon },
  data () {
    return {
      loading: false,
      added: false,
      failed: false,
      failTimeout: null,
      rulePrice: false
    }
  },
  watch: {
    loading: {
      handler: function (val, oldVal) {
        // this.$bus.$emit('modal-close-state', 'modal-product-' + this.categoryId + '-' + this.productId, !val)
      }
    }
  },
  computed: {
    ...mapGetters({
      productsInCart: 'cart/getCartItems'
    }),
    isProductDisabled () {
      return this.disabled || formatProductMessages(this.product.errors) !== '' || this.isAddingToCart || !this.isInStock || this.OfflineOnly
    },
    isInStock () {
      return this.product.stock && this.product.stock.is_in_stock
    },
    isLoading () {
      return this.isProductPage ? this.loading : this.isAddingToCart
    }
  },
  methods: {
    onAfterRemovedVariant () {
      this.$forceUpdate()
    },
    canBeAdded (product) {
      return formatProductMessages(product.errors) !== ''
    },
    addToCartWrapper: debounce(async function(product) {
      this.onBeforeAdd()

      if (!this.productsInCart.length && this.rulePrice) {
        await this.addProductToCart(product)
        // await this.$store.dispatch('cart/updateQuantity', { product: this.product, qty: this.product.qty })
      } else {
        await this.addProductToCart(product)
      }
    }, 500, {leading: true, trailing: false}),
    onBeforeAdd () {
      this.loading = true
      this.added = false
      this.failed = false

      // setTimeout(() => {
      //   this.onAfterAdd({type: 'success'})
      // }, 1000)

      this.$bus.$emit('on-before-add-product', {})
    },
    onAfterAdd ({ type, sku }) {
      if (sku !== this.product.sku) {
        return
      }

      this.loading = false

      if (type === 'success') {
        this.added = true
        this.failed = false
        this.openMicrocart()
      } else {
        this.added = false
        this.failed = true
      }

      setTimeout(() => {
        this.added = false
        this.failed = false
      }, 2000)
    },
    notifyUser (notificationData) {
      this.$store.dispatch('notification/spawnNotification', notificationData, { root: true })
    },
    openMicrocart () {
      if (this.product.is_configured) return
      if (window.innerWidth < 1024) return
      this.$store.commit('ui/setMicrocart', true)
      if (this.isProductPage) this.scrollToTop()
      setTimeout(() => {
        this.$bus.$emit('microcart-close', {})
      }, 3000)
    },
    scrollToTop () {
      this.$nextTick(() => {
        window.scroll({
          behavior: 'smooth',
          left: 0,
          top: 0
        })
      })
    }
  },
  beforeMount () {
    this.$bus.$on('product-after-removevariant', this.onAfterRemovedVariant)
    this.$bus.$on('cart-after-add', this.onAfterAdd)

    this.rulePrice = this.getRulePrice(this.product)
  },
  beforeDestroy () {
    this.$bus.$off('product-after-removevariant', this.onAfterRemovedVariant)
    this.$bus.$off('cart-after-add', this.onAfterAdd)
  }
}
</script>
