import Product from '@vue-storefront/core/modules/catalog/types/Product'
import { AddToCart } from '@vue-storefront/core/modules/cart/components/AddToCart'

export const AddToCartExtend = {
  extends: 'AddToCart',
  data () {
    return {
      isAddingToCart: false
    }
  },
  props: {
    product: {
      required: true,
      type: Object
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    async addProductToCart (product: Product) {
      this.isAddingToCart = true
      try {
        const diffLog = await this.$store.dispatch('cart/cartAddItem', { productToAdd: product })

        if (diffLog) {
          if (diffLog.clientNotifications && diffLog.clientNotifications.length > 0) {
            diffLog.clientNotifications.forEach(notificationData => {
              this.notifyUser(notificationData)
            })
          }
        }

        this.$bus.$emit(`cart_${product.sku}_updated`)
        this.$bus.$emit('after-product-added', {type: 'success', sku: product.sku})
        this.$bus.$emit('cart-after-add', {type: 'success', sku: product.sku})
        this.isAddingToCart = false

        return diffLog
      } catch (err) {
        this.notifyUser({
          type: 'error',
          message: err,
          action1: { label: this.$t('OK') }
        })
        this.$bus.$emit(`cart_${product.sku}_updated`)
        this.$bus.$emit('after-product-added', {type: 'success', sku: product.sku})
        this.$bus.$emit('cart-after-add', {type: 'failed', sku: product.sku})
        this.isAddingToCart = false
        return null
      } finally {

      }
    },
    notifyUser (notificationData) {
      this.$store.dispatch('notification/spawnNotification', notificationData, { root: true })
    }
  }
}
