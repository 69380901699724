<template>
  <div class="back-in-stock-wrapper">
    <div v-if="isEnabled" class="back-in-stock">
      <p class="text-xs font-bold text-primary text-center tracking-md uppercase my-2">
        {{ $t('Out of stock') }}
      </p>
      <component
        :is="isProductPage ? 'button-with-icon' : 'button-outline'"
        class="w-full"
        @click.native="showBackInStock"
        :disabled="isWatching"
      >
        {{ isWatching ? $t('You will be notified') : $t('Notify Me') }}
      </component>
      <modal :name="`modal-back-in-stock-${product.id}`">
        <template slot="header">
          <h2 class="pl-4 pr-8 py-4 border-b border-grey-3">
            {{ product.name }} is <span class="underline">{{ $t('Out Of Stock') }}</span>
          </h2>
          <h3 class="pt-4">
            {{ $t('Notify me when available') }}
          </h3>
        </template>
        <template slot="content">
          <div class="p-4">
            <back-in-stock-form :product="product" @success="onSuccess" @error="onError" />
          </div>
        </template>
      </modal>
    </div>
    <div v-else>
      <component
        :is="isProductPage ? 'button-with-icon' : 'button-outline'"
        class="w-full"
        :disabled="true"
      >
        {{ $t('Out of Stock') }}
      </component>
    </div>
  </div>
</template>

<script>
import config from 'config'
import ButtonOutline from 'theme/components/theme/ButtonOutline'
import ButtonFull from 'theme/components/theme/ButtonFull'
import ButtonWithIcon from 'theme/components/theme/ButtonWithIcon'
import Modal from 'theme/components/core/Modal'
import BackInStockForm from 'theme/components/theme/BackInStockForm'
import i18n from '@vue-storefront/i18n'

export default {
  name: 'BackInStock',
  components: {
    ButtonOutline,
    ButtonWithIcon,
    ButtonFull,
    Modal,
    BackInStockForm
  },
  props: {
    product: {
      type: Object,
      required: true
    },
    isProductPage: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isWatching () {
      let sku = this.product.parentSku ? this.product.parentSku + '-' + this.product.sku : this.product.sku
      return this.$store.getters['klaviyo/isWatching'](sku)
    },
    isEnabled () {
      return !!config?.magento?.url && !!config?.magento?.outOfStockNotify
    }
  },
  created () {
 /*   this.$store.dispatch('klaviyo/loadWatchingList').catch(err => {
      // todo do something here?
    })*/
  },
  methods: {
    showBackInStock () {
      this.$bus.$emit('modal-show', `modal-back-in-stock-${this.product.id}`)
    },
    hideBackInStock () {
      this.$bus.$emit('modal-hide', `modal-back-in-stock-${this.product.id}`)
    },
    onSuccess () {
      this.hideBackInStock()
      this.$store.dispatch('notification/spawnNotification', {
        type: 'success',
        message: i18n.t('You will be notified as soon as product will be available.'),
        action1: { label: i18n.t('OK') }
      })
    },
    onError () {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'error',
        message: i18n.t('Could not add product to watching list'),
        action1: { label: i18n.t('OK') }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.back-in-stock {
  &::v-deep {
    .modal-container {
      @apply flex flex-col justify-center text-center;
    }
  }
}
</style>
