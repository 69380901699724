<template>
  <div v-observe-visibility="visibilityChanged" class="product-listing">
    <h3 v-if="heading" class="product-listing__heading whitespace-pre-line text-heading-page" :class="`text-${color}`">
      {{ heading }}
    </h3>
    <div class="flex flex-wrap justify-start mt-7 overflow-hidden">
      <div
        v-if="hasCallout && isVisible"
        class="lg:product-listing__item lg:justify-start lg:items-start pr-xl w-full lg:w-1/4 relative lg:flex"
        :class="[`lg:w-1/${columns}`]"
      >
        <slot name="callout" />
      </div>
      <div
        v-for="product in products"
        :key="product.id"
        class="product-listing__item w-1/2 relative"
        :class="[`lg:w-1/${columns}`]"
      >
        <product-tile
          :product="product"
          :color="color" class="h-full pt-5"
          :show-add-to-cart-btn="showAddToCartBtn"
          :category-id="categoryId"
          :product-id="product.id"
          :data-sku="product.sku"
          :data-type-id="product.type_id"
          :data-rewards-enabled="product.can_spend ? 1 : 0"
          :data-in-stock="product.stock && product.stock.is_in_stock ? 1 : 0"
        >
          <template v-slot:recommendedBadge>
            <recommended-badge :sku="product.sku" class="z-10 absolute top-0 right-0"></recommended-badge>
          </template>
        </product-tile>
        <product-popup
          :product="product"
          :child-sku="product.childSku"
          :parent-sku="product.parentSku"
          :category-id="categoryId"
          :product-id="product.id"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ProductTile from 'theme/components/core/ProductTile'
import ProductPopup from 'theme/components/core/blocks/Category/ProductPopup'
import RecommendedBadge from 'theme/components/core/RecommendedBadge'

export default {
  name: 'ProductListing',
  components: {
    ProductTile,
    ProductPopup,
    RecommendedBadge
  },
  props: {
    categoryId: {
      type: [Number, String],
      required: true
    },
    heading: {
      type: String,
      required: false,
      default: null
    },
    showAddToCartBtn: {
      type: Boolean,
      required: false,
      default: true
    },
    products: {
      type: null,
      required: true
    },
    columns: {
      type: [Number, String],
      required: true
    },
    color: {
      type: String,
      required: false,
      default: 'primary'
    }
  },
  data () {
    return {
      isVisible: false
    }
  },
  computed: {
    hasCallout () {
      return !!this.$slots['callout']
    }
  },
  methods: {
    visibilityChanged (isVisible, entry) {
      if (isVisible) this.isVisible = true
    }
  }
}
</script>

<style lang="scss" scoped>
.product-listing {
  &__heading {
    text-indent: -.75em;
    padding-left: .7em;
  }

  &__subheader {
    &:after {
      @screen lg {
        @apply border-t absolute;
        content: '';
        width: 320px;
        height: 0;
        top: 167px;
        right: -46px;
        transform-origin: center center;
        transform: rotate(-45deg);
      }
    }
  }

  &__item {
    @screen md {
      &:nth-child(odd):before {
        display: block;
        width: 200%;
      }
    }

    @screen lg {
      &:nth-child(4n+1):before {
        display: block;
        width: 400%;
      }
    }
  }
}
</style>
