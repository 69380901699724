<template>
  <component
    :is="btnSolid ? 'button-full' : 'button-outline'"
    @click.native="addToCartWrapper(product)"
    :disabled="canBeAdded(product)"
    data-testid="addToCart"
    :color="color"
    :small="true"
    :iconClass="`ss-cart`"
    :loading="loading">
    <span v-if="added">
      {{ $t('Added') }}
    </span>
    <span v-if="failed">
      {{ $t('Failed') }}
    </span>
    <span class="inline-block align-middle">
      <slot v-if="!added && !failed">
        {{ $t('Add to cart') }}
      </slot>
    </span>
    <i v-if="!btnSolid" class="ss-gizmo ss-cart icon-lg inline-block align-middle text-h3"></i>
  </component>
</template>

<script>
import { formatProductMessages } from '@vue-storefront/core/filters/product-messages'
import focusClean from 'theme/components/theme/directives/focusClean'
import ButtonOutline from 'theme/components/theme/ButtonOutline'
import ButtonWithIcon from 'theme/components/theme/ButtonWithIcon'
import ButtonFull from 'theme/components/theme/ButtonFull'
import { AddToCart } from '@vue-storefront/core/modules/cart/components/AddToCart'

export default {
  mixins: [AddToCart],
  props: {
    categoryId: {
      type: [Number, String],
      required: true
    },
    productId: {
      type: [Number, String],
      required: true
    },
    color: {
      type: String,
      requred: false,
      default: 'primary'
    },
    btnSolid: {
      type: Boolean,
      default: false,
    }
  },
  directives: { focusClean },
  components: { ButtonOutline, ButtonFull },
  data () {
    return {
      loading: false,
      added: false,
      failed: false,
      failTimeout: null
    }
  },
  methods: {
    onAfterRemovedVariant () {
      this.$forceUpdate()
    },
    canBeAdded (product) {
      return formatProductMessages(product.errors) !== ''
    },
    addToCartWrapper (product) {
      // this.onBeforeAdd()
      // this.addToCart(product)
      this.$bus.$emit('modal-show', 'modal-product-' + this.categoryId + '-' + this.productId)
    },
    onBeforeAdd () {
      this.loading = true
      this.added = false
      this.failed = false

      this.failTimeout = setTimeout(() => {
        this.onAfterAdd({ type: 'error' })
      }, 10000)
    },
    onAfterAdd ({ type, sku }) {
      if (sku !== this.product.sku) {
        return
      }

      this.loading = false

      if (this.failTimeout) {
        clearTimeout(this.failTimeout)
      }

      if (type === 'success') {
        this.added = true
        this.failed = false
      } else {
        this.added = false
        this.failed = true
      }

      setTimeout(() => {
        this.added = false
        this.failed = false
      }, 2000)
    }
  },
  beforeMount () {
    this.$bus.$on('product-after-removevariant', this.onAfterRemovedVariant)
    this.$bus.$on('cart-after-add', this.onAfterAdd)
  },
  beforeDestroy () {
    this.$bus.$off('product-after-removevariant', this.onAfterRemovedVariant)
    this.$bus.$off('cart-after-add', this.onAfterAdd)
  }
}
</script>
