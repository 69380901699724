<template>
  <div
    v-if="earnPoints && product.point_can_earn"
    class="earn-points"
  >
    <p class="text-average text-dark-1 leading-none mb-sm">
      {{ $t(`Earn ${bankName} Points with this product`) }}
    </p>
    <div class="mb-sm">
      <div class="flex">
        <p class="earn-points__label relative bg-black w-30% text-white text-average leading-none font-serif italic font-semibold px-xs py-sm flex relative items-center product__points-label">
          {{ $t('You will earn:') }}
        </p>
        <p class="bg-green w-70% text-dark-2 text-h6 font-black py-sm pr-sm pl-md uppercase flex items-center tracking-sm leading-none">
          <span>{{ getEarnPoints }}&nbsp;</span>
          <span>{{ $t('Loyalty Points') }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PriceRules from 'theme/mixins/blocks/PriceRules'

export default {
  name: 'EarnPoints',
  mixins: [PriceRules],
  props: {
    product: {
      type: Object,
      required: true,
      default: null
    },
    qty: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {
      bankCode: '',
      bankName: '',
      earnPoints: 0,
      productQty: this.qty
    }
  },
  async beforeMount () {
    const currentAccount = this.getCustomerAccounts.filter(account => account.bank.code === this.getBrandCode(this.product.brand_id))[0]

    if (!currentAccount || !this.product.point_can_earn) return

    this.bankCode = currentAccount.bank.code
    this.bankName = currentAccount.bank.name

    const estimatePoints = await this.$store.dispatch('loyalty/loyaltyEstimatePoints', {
      items: [
        {
          sku: this.product.sku,
          account: this.bankCode,
          type: String(this.product.point_redemption_type || 'unknown'),
          qty: 1,
          price: this.getRulePrice(this.product) || 0,
          earning_multiplier: this.product.point_earning_multiplier || 1,
          redemption: false,
          can_earn: !!this.product.point_can_earn
        }
      ]
    })

    if (!estimatePoints.accounts) return

    this.earnPoints = ((Object.values(estimatePoints.accounts[this.bankCode].items)
      .filter(item => item.sku === this.product.sku) || [])[0] || {})['earning']
  },
  computed: {
    ...mapGetters({
      getCustomerAccounts: 'loyalty/getCustomerAccounts',
      getBrands: 'brands/getBrands'
    }),
    getEarnPoints () {
      let earnPoints = this.earnPoints * this.qty

      return earnPoints.toFixed(2)
    }
  },
  methods: {
    getBrandCode (id) {
      const brands = this.getBrands || []
      const brand = brands.find(brand => brand.id === id)

      if (!brand) return false

      if (brand.parent_id) {
        return this.getBrandCode(brand.parent_id)
      }
      return brand.code
    }
  },
  watch: {
    qty: {
      handler (value) {
        this.productQty = value
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .earn-points {
    &__label {
      &:after {
        @apply absolute block border-t-6 border-b-6 border-l-6 border-solid border-transparent z-10;
        border-left-color: #000;
        content: '';
        right: -6px;
        top: 50%;
        transform: translate(0, -50%);
      }
    }
  }
</style>
