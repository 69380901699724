<template>
  <div v-if="getProductsList.length" class="links">
    <div class="flex items-center">
      <div class="w-1/2">
        <div
          v-if="selectedProduct.product"
          class="product-links__price"
        >
          <p class="text-h6 uppercase text-dark leading-thick">
            {{ $t('Price') }}
          </p>
          <div class="font-normal text-heading-page italic leading-h5 font-serif mt-xs mb-md pr-10">
            <div v-show="!rulePrice">
              <div v-if="selectedProduct.product.special_price && selectedProduct.product.priceInclTax && selectedProduct.product.originalPriceInclTax">
                <span class="price-special">{{ selectedProduct.product.priceInclTax | price }}</span>&nbsp;
                <span class="price-original">{{ selectedProduct.product.originalPriceInclTax | price }}</span>
              </div>
              <div v-if="!selectedProduct.product.special_price && selectedProduct.product.priceInclTax">
                {{ selectedProduct.product.qty > 0 ? selectedProduct.product.priceInclTax : selectedProduct.product.priceInclTax | price }}
              </div>
            </div>
            <div v-show="rulePrice">
              {{ rulePrice | price }}
            </div>
          </div>
        </div>
      </div>
      <div class="w-1/2">
        <div class="text-average leading-none pl-md">
          {{ $t('Available for purchase in increments of ') + incrementStep }}
        </div>
      </div>
    </div>
    <div class="flex items-stretch mt-6 mb-sm">
      <p class="flex items-center justify-center border border-dark border-r-0 text-h5 tracking-md uppercase w-16 p-2">
        {{ optionTitle ? optionTitle : $t('Size') }}
      </p>
      <base-select
        name="product-links"
        class="product-links__links"
        v-model="productLinks"
        @change.native="changeProduct"
        :options="getProductLinks"
        :selected="selectedOption"
      />
    </div>
    <product-link :product="selectedProduct" :is-product-page="isProductPage" />
  </div>
</template>

<script>
import i18n from '@vue-storefront/i18n'
import BaseSelect from 'theme/components/core/blocks/Form/BaseSelect'
import ProductLink from './ProductLink'
import PriceRules from 'theme/mixins/blocks/PriceRules'
import { getProductQtyIncrement } from 'theme/helpers/getProductQtyIncrement'

export default {
  name: 'ProductLinks',
  components: {
    BaseSelect,
    ProductLink
  },
  mixins: [PriceRules],
  props: {
    product: {
      type: Object,
      required: false,
      default: () => {}
    },
    products: {
      type: Array,
      required: true,
      default: () => []
    },
    isProductPage: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      productLinks: 0,
      selectedOption: 0,
      selectedProduct: {},
      incrementStep: 1,
      rulePrice: false
    }
  },
  beforeMount() {
    this.selectedProduct = this.getProductsList.length ? this.getProductsList[0] : {}
    if (!this.selectedProduct.product) return
    this.rulePrice = this.getRulePrice(this.selectedProduct.product)
    this.incrementStep = getProductQtyIncrement(this.selectedProduct.product)
  },
  computed: {
    optionTitle () {
      if (!this.product || this.product.type_id !== 'bundle') return false
      return i18n.t(this.product['bundle_options'][0]['title'])
    },
    getProductLinks () {
      if (!this.getProductsList.length) return []

      return this.getProductsList.map((link, index) => {
        return {
          value: index,
          label: `${i18n.t('Option')} ${index + 1}`
        }
      })
    },
    getProductsList() {
      return this.products || []
    }
  },
  methods: {
    changeProduct () {
      this.selectedOption = Number(this.productLinks)
      this.selectedProduct = this.getProductsList[this.selectedOption]
    }
  },
  watch: {
    selectedOption: {
      immediate: true,
      handler: function (newVal) {
        let selectedProduct = this.getProductsList[Number(newVal)]
        let productInfo = selectedProduct.product ? {
          option: `${i18n.t('Option')} ${Number(newVal) + 1}`,
          sku: selectedProduct.product.sku,
          sizingOz: selectedProduct.product.sizing_oz
        } : {}
        if (selectedProduct.product) this.rulePrice = this.getRulePrice(selectedProduct.product)
        this.$bus.$emit('selected-new-product', productInfo)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.product-links {
  &__price {
    @apply relative;

    &:after {
      @apply absolute border-t border-dark;
      content: '';
      bottom: 16px;
      height: 0;
      left: 90%;
      margin-left: -40px;
      opacity: 1;
      transform: rotate(-45deg);
      transform-origin: center center;
      transition: opacity 0.25s ease;
      width: 67px;
    }
  }

  &__links {
    width: calc(100% - 4.35rem);

    &::v-deep {
      select {
        @apply py-md;
      }
    }
  }
}
</style>
