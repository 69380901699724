<template>
  <div v-if="canShowBadge" :class="shape">
    <svg xmlns="http://www.w3.org/2000/svg" :style="shapeStyle" aria-hidden="true" :viewBox="selectedShape.viewBox" v-html="selectedShape.path"></svg>
    <div class="text">
      <div class="title">{{ title }}</div>
      <div class="subtitle">{{ subtitle }}</div>
    </div>
  </div>
</template>

<script>
import config from 'config'
import { mapState } from 'vuex'

export default {
  name: "RecommendedBadge",
  props: {
    sku: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      shapes: {
        starburst: {
          viewBox: "-32 -32 64 64",
          path: `<path d="M 19.5 7.5 L 32 0 L 19.5 -7.5 L 22 -22 L 7 -18 L 0 -32 L -7 -18 L -22 -22 L -19.5 -7.5 L -32 0 L -19.5 7.5 L -22 22 L -7 18 L 0 32 L 7 18 L 22 22 Z"/>`,
        },
        circle: {
          viewBox: "-32 -32 64 64",
          path: `<circle cx="0" cy="0" r="27" />`,
        },
        pill: {
          viewBox: "0 0 120 40",
          path: `
          <circle cx="40" cy="20" r="20" />
          <polygon points="40,0 80,0 80,40 40,40" />
          <circle cx="80" cy="20" r="20" />
          `
        }
      },
      title: config.recommended.title_text || "LAST ORDERED",
      shape: config.recommended && config.recommended.badgeShape ? config.recommended.badgeShape : 'pill',
    }
  },
  computed: {
    ...mapState({
      customRecommended: state => state.recommended.customRecommended
    }),
    selectedShape () {
      return this.shapes[this.shape]
    },
    shapeStyle () {
      return {
        fill: config.recommended && config.recommended.badgeColor ? config.recommended.badgeColor : '#24bac5'
      }
    },
    canShowBadge() {
      // if sku is in the customRecommended list
      if (this.customRecommended[this.sku]) {
        return true
      }
    },
    isThresholdMet() {
      if (!config.recommended || !config.recommended.customRecommendedThreshold) {
        return false
      }
      return (this.customRecommended[this.sku] || 0) > config.recommended.customRecommendedThreshold
    },
    subtitle() {
      const days = config.recommended.customRecommendedThreshold || 30
      if (this.customRecommended[this.sku] === -1 || this.customRecommended[this.sku] > days) {
        return `${days}+ days ago`
      } else {
        return `${this.customRecommended[this.sku]} days ago`
      }
    }
  }
}
</script>

<style scoped>
svg {
  display: block;
  top: 0;
  right: 0;
  width: 125px;
  height: 125px;
}
.text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  font-size: 0.8rem;
  text-align: center;
}
.title {
  font-weight: bold;
}
.starburst {
  top: -20px;
}
.starburst .text{
  top: 47%;
}
.pill svg {
  width: auto;
  height: 55px;
}
.circle {
  top: -20px;
}
.circle svg {
  width: auto;
  height: 115px;
}
.subtitle {
  margin-top: -10px;
}
</style>